<template>
  <StoryblokComponent v-if="componentExists(blok.component)" :blok="blok" />
  <div
    v-else
    class="text-dark bg-light my-16 inline-flex w-full items-center justify-center space-x-2 rounded-lg p-8 text-xl"
  >
    <span class="font-light">
      Component named
      <span class="font-medium">{{ blok.component }}</span>
      is not defined in your frontend framework yet.
    </span>
  </div>
</template>

<script setup>
defineProps({ blok: Object })
const componentExists = (component) =>
  typeof resolveComponent(component) !== 'string'
</script>
